'use strict'


// CAROUSEL SLIDER
// https://flickity.metafizzy.co
const slider = document.getElementsByClassName('slider')
for (var i = 0; i < slider.length; i++) {

	const sliderCount = slider[i].dataset.moduleCount
	const sliderGroup = document.querySelector('.slider__wrapper-' + sliderCount)

	new Flickity(sliderGroup, {
		wrapAround: true,
		pageDots: false,
		autoPlay: parseInt(sliderGroup.dataset.delay),
		selectedAttraction: 0.1,
		friction: 0.9
	})
}


// NEWSFEED MASONRY
// https://packery.metafizzy.co
// http://packery.metafizzy.co.s3-website-us-east-1.amazonaws.com/extras.html
// https://imagesloaded.desandro.com/
const news = document.getElementsByClassName('newsfeed')
for (var i = 0; i < news.length; i++) {

	const masonryCount = news[i].dataset.moduleCount
	const masonryGroup = document.querySelector('.masonry-' + masonryCount)

	const masonryOptions = {
		itemSelector: '.masonry__item',
		transitionDuration: '0.3s',
		gutter: 0
	}
	new Packery(masonryGroup, masonryOptions)

	imagesLoaded(masonryGroup, function() {
		new Packery(masonryGroup, masonryOptions)
		masonryGroup.classList.add('masonry__loaded')
	})
}


// BUGUETTEBOX (GALERY LIGHTBOX)
var gallery = document.querySelector('.gallery')
if (gallery) {
	baguetteBox.run('.gallery', {
		overlayBackgroundColor: 'rgba(0, 0, 0, .96)',
		leftArrow: 'left'
	})
}


// AUDIO CLIP PLAYER
const clips = plyr.setup({
	controls: ['play', 'progress', 'current-time']
})

if (clips) {
	var i = 1
	clips.forEach(function (clip) {
		clip.on('ready', function () {
			clip.getContainer().setAttribute('id', 'plyrid-' + i)
			clip.plyrid = 'plyr-' + i
			i++
		});
		clip.on('play', function () {
			var currentPid = clip.plyrid
			clips.forEach(function (clip) {
				if (currentPid != clip.plyrid) {
					clip.pause()
				}
			})
		})
	})
}


// NAV TOGGLE (BUTTON & MENU)
const toggle = document.querySelector('.toggle')
const nav = document.querySelector('.nav')

if (toggle) {
	toggle.addEventListener('click', function (e) {
		e.preventDefault();
		(toggle.classList.contains('open') === true) ? toggle.classList.remove('open') : toggle.classList.add('open');
		(nav.classList.contains('open') === true) ? nav.classList.remove('open') : nav.classList.add('open');
	})
}
